export const getTimeFromTimestamp = timestamp => {
  const timeValues = new Date(timestamp * 1000).toLocaleTimeString().split(':');
  timeValues.splice(-1, 1);
  return timeValues.join(':');
};

const msPerMinute = 60 * 1000;
const msPerHour = msPerMinute * 60;
const msPerDay = msPerHour * 24;
const msPerMonth = msPerDay * 30;
const msPerYear = msPerDay * 365;

export function plural(forms, n) {
  let idx;
  if (n % 10 === 1 && n % 100 !== 11) {
    idx = 0;
  } else if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
    idx = 1;
  } else {
    idx = 2;
  }
  return forms[idx] || '';
}

export function timeDifference(current, previous) {
  const elapsed = current - previous;
  let wordForms;
  let value;

  if (elapsed < msPerMinute) {
    value = Math.round(elapsed / 1000);
    wordForms = ['секунду', 'секунды', 'секунд'];
  } else if (elapsed < msPerHour) {
    value = Math.round(elapsed / msPerMinute);
    wordForms = ['минуту', 'минуты', 'минут'];
  } else if (elapsed < msPerDay) {
    value = Math.round(elapsed / msPerHour);
    wordForms = ['час', 'часа', 'часов'];
  } else if (elapsed < msPerMonth) {
    value = Math.round(elapsed / msPerDay);
    wordForms = ['день', 'дня', 'дней'];
  } else if (elapsed < msPerYear) {
    value = Math.round(elapsed / msPerMonth);
    wordForms = ['месяц', 'месяца', 'месяцев'];
  } else {
    value = Math.round(elapsed / msPerYear);
    wordForms = ['год', 'года', 'лет'];
  }

  return `${value} ${plural(wordForms, value)} назад`;
}

export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function setCookie(name, value, props) {
  props = props || {};
  let exp = props.expires;
  if (typeof exp == 'number' && exp) {
    const d = new Date();
    d.setTime(d.getTime() + exp * 1000);
    exp = props.expires = d;
  }
  if (exp && exp.toUTCString) {
    props.expires = exp.toUTCString();
  }
  value = encodeURIComponent(value);
  let updatedCookie = name + '=' + value;
  for (const propName in props) {
    updatedCookie += '; ' + propName;
    const propValue = props[propName];
    if (propValue !== true) {
      updatedCookie += '=' + propValue;
    }
  }
  document.cookie = updatedCookie;
}

export function deleteCookie(name) {
  setCookie(name, null, { expires: -1 });
}
