import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { ChatsList } from '../components/chats-list';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import listStyles from './list.module.css';
import { deserializeQuery, useChatsData } from '../services/api';
import { Avatar } from '../components/avatar';
import { Input } from '../components/input';
import logoutImage from '../images/logout.svg';
import { ChatPage } from './chat';
import { useAuth } from '../services/auth';

export const ListPage = () => {
  const [searchValue, setSearch] = useState('');
  const { data, loading } = useChatsData();
  const history = useHistory();
  const { search } = useLocation();
  const { signOut } = useAuth();
  const { path } = useRouteMatch();

  const onChange = e => {
    setSearch(e.target.value);
  };

  useEffect(
    () => {
      if (searchValue) {
        history.replace({ search: `?q=${searchValue}` });
      } else {
        history.replace({ search: '' });
      }
    },
    [searchValue, history, search]
  );
  useEffect(
    () => {
      if (search) {
        const { q } = deserializeQuery(search);
        setSearch(q);
      }
    },
    [search]
  );

  const preparedData = useMemo(
    () => {
      return (
        !loading &&
        data &&
        data.length &&
        data.filter(
          chat =>
            chat.recipientName.toLocaleLowerCase().indexOf(searchValue.toLocaleLowerCase()) > -1
        )
      );
    },
    [data, searchValue, loading]
  );

  const content = loading ? (
    'loading'
  ) : preparedData && preparedData.length ? (
    <ChatsList chats={preparedData} />
  ) : null;

  let logout = useCallback(
    () => {
      signOut().then(() => {
        history.replace({ pathname: '/login' });
      });
    },
    [signOut, history]
  );

  return (
    <div className={listStyles.container}>
      <div className={listStyles.list}>
        <div className={listStyles.searchbar}>
          <Avatar name={'A'} />
          <Input placeholder="Поиск" onChange={onChange} value={searchValue} />
          <img alt="logout" src={logoutImage} onClick={logout} />
        </div>
        {content}
      </div>
      <Switch>
        <Route path={`${path}/:chatId`}>
          <ChatPage />
        </Route>
      </Switch>
    </div>
  );
};
