import { Redirect } from 'react-router-dom';
import { useAuth } from '../services/auth';
import React, { useCallback, useState } from 'react';
import styles from './login.module.css';
import { Button } from '../components/button';
import { Input } from '../components/input';
import { PasswordInput } from '../components/password-input';

export function LoginPage() {
  let auth = useAuth();

  const [form, setValue] = useState({ email: '', password: '' });

  const onChange = e => {
    setValue({ ...form, [e.target.name]: e.target.value });
  };

  let login = useCallback(
    e => {
      e.preventDefault();
      auth.signIn(form);
    },
    [auth, form]
  );

  if (auth.user) {
    return (
      <Redirect
        to={{
          pathname: '/list'
        }}
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      <form className={styles.form}>
        <h1 className={styles.heading}>Вход</h1>
        <Input placeholder="Логин" value={form.email} name="email" onChange={onChange} />
        <PasswordInput
          placeholder="Пароль"
          value={form.password}
          name="password"
          onChange={onChange}
        />
        <Button onClick={login} primary={true}>
          Войти
        </Button>
      </form>
    </div>
  );
}
